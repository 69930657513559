<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      no-filters
      @filterOption="onFiltersChange"
      @onChange="fetchData()"
    >
      <template v-slot:actions="{ item }">
        <v-icon
          v-if="$admin.can('roles.edit')"
          class="mr-2"
          small
          @click="editRoles(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="$admin.can('roles.delete')"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <!-- add Filters -->
      <template #addFilters>
        <v-card
          v-if="authUser.isAdmin"
          class=""
        >
          <v-autocomplete
            v-model="customFilters.type"
            filled
            dense
            clearable
            :items="userTypeList"
            item-text="name"
            item-value="id"
            :label="$t('role_type')"
            no-filter
            @change="fetchData"
          />
        </v-card>
        <v-card v-if="authUser.isAdmin && customFilters.type == 'product'">
          <v-select
            v-model="customFilters.product_tier_id"
            filled
            dense
            clearable
            :items="tiersOptions"
            item-value="id"
            item-text="product_tier_name"
            :label="$t('product_tiers')"
            no-filter
            @change="fetchData"
          />
        </v-card>
      </template>
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div>
          <create-roles
            v-if="$admin.can('roles.create')"
            :filter-options="{ ...options, ...customFilters }"
          />
          <edit-roles
            v-if="$admin.can('roles.edit') && isEditRoleModal"
            :items="isSelected"
            @close="closeEditModal"
          />
          <confirmation-modal
            v-if="$admin.can('roles.delete')"
            :is-visible="deleteModal"
            :toggle="toggleDeleteModal"
            :row="selectedRow"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    CreateRoles: () => import("./dialogs/CreateRoles.vue"),
    EditRoles: () => import("./dialogs/EditRoles.vue"),
    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      customFilters: {},
      //
      filters: {},
      subFilters: {},

      //
      isEditRoleModal: false,
      isSelected: {},
      //
      userTypeList: [
        {
          id: "orko",
          name: "ORKO",
        },
        {
          id: "product",
          name: "Product",
        },
      ],
      headers: [
        {
          text: this.$t("id"),
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: this.$t("name"),
          align: "start",
          value: "name",
        },

        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "roles/isLoadingRoles",
      meta: "roles/meta",
      list: "roles/listRoles",
      tiersOptions: "permissions/getTiersOptions",
    }),
    _headers() {
      // computed headers for showing columns to role based
      return this.headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
  },
  watch: {
    // "customFilters.type": {
    //   handler: function (v) {
    //     // delete this.customFilters.product_tier_id;
    //   },
    // },
    // customFilters() {
    //   this.fetchData();
    // },
    options() {
      this.fetchData();
    },
  },
  async mounted() {
    await this.$store.dispatch("permissions/list");
    await this.$store.dispatch("permissions/tiersOptions");
    // for type
    if (this.$route?.query?.type) {
      this.customFilters.type = this.$route.query.type;
    }
    if (this.$route?.query?.product_tier_id) {
      this.customFilters.product_tier_id = parseInt(
        this.$route.query.product_tier_id
      );
    }
  },
  beforeDestroy() {
    const params = {
      name: "roles",
      filters: {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        ...this.customFilters,
      },
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      const params = {
        ...this.options,
        ...this.customFilters,
      };
      await this.$store.dispatch("roles/list", params).then(() => {
        // if (this.options.page > (this.meta?.lastPage || 1)) {
        //   this.options.page = 1;
        // }
      });
    },

    // Confirmation of Delete Row
    async deleteSelectedRow(row) {
      try {
        await this.$store.dispatch("roles/destroy", row);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        if (this.list.length === 0 && this.meta.lastPage == this.options.page) {
          this.options.page -= 1; // Move to the previous page
        }
        this.toggleDeleteModal("close");
        this.fetchData();
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },

    //
    async onChangeRoleType() {
      delete this.customFilters.product_tier_id;
      // this.fetchData();
      // this.options.client_id = null;
      // this.options.product_tier_id = null;
      // if (value == "oem") {
      //   try {
      //   } catch (error) {
      //     if (error.response?.status == "401") {
      //       this.$store.dispatch("logout");
      //     }
      //     this.$store.dispatch("alerts/error", error.response?.data?.message);
      //   }
      // }
    },
    //
    editRoles(item) {
      this.isSelected = item;
      this.isEditRoleModal = true;
      this.$store.dispatch("roles/openEditForm", item);
    },
    closeEditModal() {
      // this.$store.dispatch("roles/list", this.options);
      this.fetchData();
      this.isEditRoleModal = false;
    },
  },
};
</script>
<style lang="sass" scoped>
:deep .v-autocomplete.v-text-field--enclosed:not(.v-text-field--solo):not(.v-text-field--single-line):not(.v-text-field--outlined).v-input--dense .v-select__slot > input
  font-size: 0.875rem !important
</style>